import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./WriterDashboard.css";
import Dashboard from "./Dashboard";
import DraftsPage from "./DraftsPage";
import PublishedArticles from "./PublishedArticles";

// Import necessary components

const Analytics = () => <div><h2>Analytics</h2><p>View analytics of your articles.</p></div>;
const Settings = () => <div><h2>Settings</h2><p>Adjust your profile settings.</p></div>;

const WriterDashboard = () => {
  const [activeComponent, setActiveComponent] = useState("Dashboard");

  const renderComponent = () => {
    switch (activeComponent) {
      case "Dashboard":
        return <Dashboard />;
      case "My Articles":
        return <PublishedArticles />;
      case "Drafts":
        return <DraftsPage />;
      case "Analytics":
        return <Analytics />;
      case "Settings":
        return <Settings />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <div className="writer-dashboard d-flex flex-column flex-md-row">
      {/* Sidebar */}
      <div className="dashboard-sidebar p-3 bg-light">
        <h3 className="sidebar-title">Writer Panel</h3>
        <ul className="sidebar-menu list-unstyled">
          {[
            "Dashboard",
            "My Articles",
            "Drafts",
            "Analytics",
            "Settings",
          ].map((item) => (
            <li
              key={item}
              className={activeComponent === item ? "active" : ""}
              onClick={() => setActiveComponent(item)}
              style={{ cursor: "pointer", padding: "10px", fontWeight: activeComponent === item ? "bold" : "normal" }}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>

      {/* Main Content */}
      <div className="dashboard-content p-4 flex-grow-1">
        {renderComponent()}
      </div>
    </div>
  );
};

export default WriterDashboard;