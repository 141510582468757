import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "./Dashboard.css";
import api from "./Api";
import { useEffect } from "react";
import ArticleEditor from "./ArticleEditor";
const Dashboard = () => {

  const [stats, setStats] = useState({
    totalArticles: 0,
    drafts: 0,
    published: 0,
    likes: 0,
    views: 0,
});
useEffect(() => {
const fetchStats = async () => {
  try {
      const response = await api.get(`/blogs/dashboard-stats`);
      setStats(response);
  } catch (error) {
      console.error("Error fetching stats:", error);
  }
};

fetchStats();
}, []);
//   const stats = [
//     { title: "Total Articles", count: 10, icon: <FaFileAlt /> },
//     { title: "Drafts", count: 3, icon: <FaClipboardList /> },
//     { title: "Published", count: 7, icon: <FaCheckCircle /> },
//     { title: "Likes", count: 250, icon: <FaHeart /> },
//     { title: "Views", count: "1.2K", icon: <FaEye /> }
// ];


  const [isWriting, setIsWriting] = useState(false);
  const [articleTitle, setArticleTitle] = useState("");
  const [articleCategory, setArticleCategory] = useState("");
  const [coverImage, setCoverImage] = useState(null);
  const [authorName] = useState(Cookies.get("username"));
  const [publishDate, setPublishDate] = useState(new Date().toISOString().split("T")[0]);
  const [articleTags, setArticleTags] = useState("");
  const [seoTitle, setSeoTitle] = useState("");
  const [seoDescription, setSeoDescription] = useState("");
  const [articleContent, setArticleContent] = useState("");

  const token = Cookies.get("jwtToken");

  const handleCoverImageUpload = (event) => {
    const file = event.target.files[0];
    setCoverImage(file);
  };

  const handleWriteArticle = () => {
    setIsWriting(true);
  };

  const handleContentChange = (content) => {
    setArticleContent(content);
  };

  const handleSaveDraft = async () => {
    const draftData = {
      title: articleTitle,
      category: articleCategory,
      author: authorName,
      publishDate: publishDate,
      tags: articleTags,
      seoTitle: seoTitle,
      seoDescription: seoDescription,
      content: articleContent,
    };

    const formData = new FormData();
    formData.append(
      "blog",
      new Blob([JSON.stringify(draftData)], { type: "application/json" })
    );
    if (coverImage) {
      formData.append("coverImage", coverImage);
    }

    try {
      const resp = await axios.post("https://blogs.quantum-stack.com/api/blogs/draft", formData, {
        headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${token}` },
      });

      if (resp.status === 200) {
        alert("Draft Saved");
      }
    } catch (error) {
      console.error("Error saving draft", error);
      alert("Failed to save draft");
    }
  };

  const handlePreview = () => {
    const previewData = {
      title: articleTitle,
      category: articleCategory,
      coverImage: coverImage ? URL.createObjectURL(coverImage) : null,
      author: authorName,
      publishDate: publishDate,
      tags: articleTags,
      seoTitle: seoTitle,
      seoDescription: seoDescription,
      content: articleContent,
    };

    console.log("Preview Data:", previewData);
  };



  return (
    <div>
      {isWriting ? (
      <ArticleEditor setIsWriting={setIsWriting} />
      ) : (
        <div className="dashboard-content p-4 flex-grow-1">
                        <h2 className="content-title">Welcome, {authorName}!</h2>
                        <button className="btn btn-primary mt-3" onClick={handleWriteArticle}>
                            Write New Article
                        </button>
                        <div className="mt-4 d-flex flex-wrap gap-3">
                    {[
                    { title: "Total Articles", count: stats.totalArticles, icon: "📝" },
                    { title: "Drafts", count: stats.drafts, icon: "🗂" },
                    { title: "Published", count: stats.published, icon: "✅" },
                    { title: "Likes", count: stats.likes, icon: "❤️" },
                    { title: "Views", count: stats.views, icon: "👀" },
                ].map((stat, index) => (
                        <div key={index} className="p-3 text-center flex-grow-1">
                            <div className="stat-icon mb-2" style={{ fontSize: "2rem" }}>{stat.icon}</div>
                            <h5>{stat.title}</h5>
                            <p className="fs-4 fw-bold">{stat.count}</p>
                        </div>
                    ))}
                </div>
                        </div>
                
            
      )}
    </div>
  );
};

export default Dashboard;
