import React from 'react';
import './Contact.css';
import Header from './Header';

const Contact = () => {
    return (
        <>
        <Header />
        <div className="contact-container">
            
            <h1 className="contact-title">Contact Us</h1>
            <form className="contact-form">
                <div className="form-group">
                    <label className="form-label" htmlFor="name">Name:</label>
                    <input className="form-input" type="text" id="name" name="name" required />
                </div>
                <div className="form-group">
                    <label className="form-label" htmlFor="email">Email:</label>
                    <input className="form-input" type="email" id="email" name="email" required />
                </div>
                <div className="form-group">
                    <label className="form-label" htmlFor="message">Message:</label>
                    <textarea className="form-textarea" id="message" name="message" required></textarea>
                </div>
                <button className="form-button" type="submit">Submit</button>
            </form>
        </div>
        </>
    );
};

export default Contact;