import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import TextEditor from "./TextEditor";
import axios from "axios";
import { getContaint, setContaint } from "./ArticleContent";
import api from "./Api";

const ArticleEditor = ({
  articleData = {}, setIsWriting,
}) => {
  const [article, setArticle] = useState({
    id:0,
    title: "",
    category: "",
    coverImage: null,
    author: Cookies.get("username") || "",
    publishDate: new Date().toISOString().split("T")[0],
    tags: "",
    seoTitle: "",
    seoDescription: "",
    content: "",
    ...articleData, // Merge initial data if editing
  });
  console.log(articleData)
  const token = Cookies.get("jwtToken");
useEffect(() => {
    if (articleData.tags && Array.isArray(articleData.tags)) {
        setArticle((prevArticle) => ({
            ...prevArticle,
            tags: articleData.tags.join(", "),
        }));
        setContaint(articleData.content)
    }
}, [articleData]);

  const handleSaveDraft = async () => {
    const draftData = {
      id:article.id,
      title: article.title,
      category: article.category,
      author: article.author,
      publishDate: article.publishDate,
      tags:  article.tags ? article.tags.split(',').map(tag => tag.trim()) : [],
      content: getContaint(),
    };
    
    const formData = new FormData();
    formData.append(
      "blog",
      new Blob([JSON.stringify(draftData)], { type: "application/json" })
    );
    if (article.coverImage) {
      formData.append("coverImage", article.coverImage);
    }

    try {
      const resp = await axios.post("https://blogs.quantum-stack.com/api/blogs/draft", formData, {
        headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${token}` },
      });

      if (resp.status === 200) {
        alert("Draft Saved");
        setIsWriting(false);
      }
    } catch (error) {
      console.error("Error saving draft", error);
      alert("Failed to save draft");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setArticle({ ...article, [name]: value });
  };

  const handleContentChange = (content) => {
    setArticle({ ...article, content });
  };

  const handleCoverImageUpload = (event) => {
    setArticle({ ...article, coverImage: event.target.files[0] });
  };

  const handleCancel=async () => {
    if (window.confirm("Are you sure you want to cancel? You will lose any unsaved changes.")) {
      setIsWriting(false);
    }
  }

  const handleDelete=async () => {
    if (window.confirm("Are you sure you want to Delete? You will be not able to resotre once delete")) {
      const response = await api.delete("/blogs/delete?id="+articleData.id);
      console.log("Article Deleted successfully", response);
      alert("Article Deleted Successfully");
      setIsWriting(false);
    }
  }
    const handlePublish = async (id) => {
      if(articleData.id && articleData.id>0){
        try {
          const response = await api.post("/blogs/publish?id="+articleData.id);
          console.log("Article published successfully", response);
          alert("Article Published Successfully");
          setIsWriting(false);
        } catch (error) {
          console.error("Error publishing article", error);
          alert("Failed to publish article");
        }
      }
    
    };

  return (
    <div className="article-editor p-3">
      <h2>{articleData.id ? "Edit Article" : "Write New Article"}</h2>

      <label>Title</label>
      <input
        type="text"
        name="title"
        className="form-control mb-3"
        value={article.title}
        onChange={handleChange}
      />

      <label>Category</label>
      <select
        name="category"
        className="form-control mb-3"
        value={article.category}
        onChange={handleChange}
      >
        <option value="">Select Category</option>
        <option value="Tech">Tech</option>
        <option value="Health">Health</option>
        <option value="Business">Business</option>
        <option value="Lifestyle">Lifestyle</option>
      </select>

      <label>Cover Image</label>
      <input type="file" className="form-control mb-3" onChange={handleCoverImageUpload} />
      <label>Tags (comma-separated)</label>
      <input
        type="text"
        name="tags"
        className="form-control mb-3"
        value={article.tags}
        onChange={handleChange}
      />

      <label>Article Content</label>
      <TextEditor  value={article.content} onChange={handleContentChange} />

      <div className="article-actions d-flex justify-content-between">
        <button className="btn btn-secondary" onClick={handleSaveDraft}>Save Draft</button>
       
        {articleData.id && (<>
        <button className="btn btn-success" onClick={handlePublish}>Publish</button>
          <button className="btn btn-danger" onClick={handleDelete} >Delete</button>
          </>
        )}
         <button className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
       
      </div>
    </div>
  );
};

export default ArticleEditor;
