import React, { useEffect, useState } from "react";
import axios from "axios";
import api from "./Api";
import ArticleEditor from "./ArticleEditor";
import { setContaint } from "./ArticleContent";

const DraftsPage = () => {
    const [drafts, setDrafts] = useState([]);
    const [edit, setEdit]=useState(false);
    const[selectedArticle, setSelectedArticle]=useState({});

    useEffect(() => {
        fetchDrafts();
    }, []);

    const fetchDrafts = async () => {
        try {
            const response = await api.get(`/blogs/drafts`);
            setDrafts(response);
        } catch (error) {
            console.error("Error fetching drafts:", error);
        }
    };

    const handleEdit = (article) => {
        if(article){
            setContaint(article.content)
            setSelectedArticle(article)
        }
        setEdit(true)
    };

    
  const handleDelete=async (id) => {
    if (window.confirm("Are you sure you want to Delete? You will be not able to resotre once delete")) {
    try {
        const response = await api.delete("/blogs/delete?id="+id);
        console.log("Article Deleted successfully", response);
        alert("Article Deleted Successfully");
    } catch (error) {
        console.error("Error Deleting article", error);
        alert("Failed to Deleting article");
      }
    }
  }
    const handlePublish = async (id) => {
      if(id && id>0){
        try {
          const response = await api.post("/blogs/publish?id="+id);
          console.log("Article published successfully", response);
          alert("Article Published Successfully");
        } catch (error) {
          console.error("Error publishing article", error);
          alert("Failed to publish article");
        }
      }
    
    };

    return (
        <div className="container mt-4">
            {edit ?(
                <ArticleEditor articleData={selectedArticle}  setIsWriting={setEdit}/>
            ):
            (<div>
                <h2>Draft Articles</h2>
            <div className="row">
                {drafts.map((draft) => (
                    <div key={draft.id} className="col-md-4 mb-3">
                        <div className="card p-3 shadow">
                            <h5>{draft.title}</h5>
                            <p className="text-truncate">{draft.content}</p>
                            <div className="d-flex justify-content-between">
                                <button className="btn btn-warning" onClick={() => handleEdit(draft)}>
                                    Edit
                                </button>
                                <button className="btn btn-success" onClick={() => handlePublish(draft.id)}>
                                    Publish
                                </button>
                                <button className="btn btn-danger" onClick={() => handleDelete(draft.id)}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            </div>)}
        </div>
    );
};

export default DraftsPage;
