import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "./Api";
import "./ArticlePage.css";
import Header from "./Header";
import Cookies from 'js-cookie';
import {FaSpinner} from 'react-icons/fa';

const ArticlePage = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const[user, setUser]=useState();

  useEffect(() => {
    fetchArticle();
    const username=Cookies.get('username');
    if(username)
      setUser(username);
  }, [slug]);

  const fetchArticle = async () => {
    try {
      const response = await api.get(`/blogs/read/${slug}`);
      setArticle({
        ...response,
        coverImage: response.coverImage
          ? `data:image/jpeg;base64,${response.coverImage}`
          : null,
      });
      fetchComments(response.id);
    } catch (error) {
      console.error("Error fetching article:", error);
    }
  };

  const fetchComments = async (articleId) => {
    try {
      const response = await api.get(`/blogs/comments/${articleId}`);
      setComments(response);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const handleCommentSubmit = async () => {
    if (!newComment?.trim()) return;
    try {
      const commentData = {
        blogId: article.id,
        text: newComment,
      };
      await api.post(`/blogs/${article.id}/comment`, commentData);
      setNewComment();
      setNewComment("");
      await fetchComments(article.id)

    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };
  const handleNextArticle = () => {
    // const currentIndex = articles.findIndex((a) => a.id === article.id);
    // if (currentIndex < articles.length - 1) {
    //   setArticle(articles[currentIndex + 1]);
    //   fetchComments(articles[currentIndex + 1].id);
    // }
  };

  const handleLike = async () => {
    try {
      setArticle({ ...article, likes: article.likes + 1 });
      await api.post(`/blogs/like/${article.slug}`);
      setArticle({ ...article, likes: article.likes + 1 });
    } catch (error) {
      console.error("Error liking article:", error);
    }
  };


  if (!article) return <div className="loader">
  <FaSpinner />
 Loading...</div>;

  return (
    <div className="article-page-contener">
        <Header />
      <div className="article-page">
          <button className="back-btn" onClick={() => window.location="/"}>
            ⬅ Back
          </button>
          <h1 className="article-title">{article.title}</h1>
          {article.coverImage && (
            <img
              src={article.coverImage}
              alt={article.title}
              className="article-image"
            />
          )}


          
          <div className="article-meta">
            <span><strong>Category:</strong> {article.category}</span>
            <span><strong>Author:</strong> {article.author}</span>
            <span><strong>Published:</strong> {article.publishDate}</span>
            <span><strong>Likes:</strong> {article.likes}</span>
            {article.tags.length > 0 && (
              <span>
                <strong>Tags:</strong> {article.tags.join(", ")}</span>
            )}
          </div>

          <p
            className="article-content"
            dangerouslySetInnerHTML={{ __html: article.content }}
          ></p>

          <div className="article-actions">
            <button className="like-btn" onClick={handleLike}> {article.likes} ❤️  </button>
            <button className="view-btn" > {article.views} 👁️  </button>
            {/* <button className="next-btn" onClick={handleNextArticle}>➡ Next Article</button> */}
          </div>

         
          <div className="comments-section">
            <h3>Comments</h3>
            <ul>
              {comments.map((comment, index) => (
                <li key={index} className="comment-card">
                  <div className="comment-header">
                    <strong>@{comment.username}</strong> <span> &nbsp;&nbsp; {new Date(comment.createdAt).toLocaleString()}</span>
                  </div>
                  <p>{comment.text}</p>
                </li>
              ))}
            </ul>
            <textarea
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Add a comment..."
              disabled={!user}
            ></textarea>
            <button onClick={handleCommentSubmit} disabled={!user}>Submit</button>
            {!user && <>
              &nbsp;&nbsp;&nbsp; First <a href="/login">Login</a> to add comment
              </>}
          </div>
          <div className="social-share">
            <h3>Share this article:</h3>
            <a href={`https://wa.me/?text=${window.location.href}`} target="_blank" rel="noopener noreferrer">📱 WhatsApp</a>
            <a href={`https://www.linkedin.com/shareArticle?url=${window.location.href}`} target="_blank" rel="noopener noreferrer">🔗 LinkedIn</a>
            <a href={`https://twitter.com/intent/tweet?url=${window.location.href}`} target="_blank" rel="noopener noreferrer">🐦 Twitter</a>
          </div>

        </div>
  </div>
  );
};

export default ArticlePage;
