import React from 'react';
import './Header.css'; // Assuming you have some basic styles
import './logo.png';
import Cookies from 'js-cookie';
import { FaUser } from 'react-icons/fa';

const Header = () => {
    const [menuOpen, setMenuOpen] = React.useState(false);
    const token=Cookies.get('jwtToken');
    const username=Cookies.get('username');
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    const handleLogout=()=>{
        Cookies.remove('jwtToken');
        Cookies.remove('username');
        window.location.href = '/';
    }
    return (
        <div>
        <header className="site-header">
            <div className="header-logo">
                <img src="/logo.png" alt="Sushens Blogs" />
                <h1>Sushens Blogs</h1>
            </div>
            <button className="menu-toggle" onClick={toggleMenu}>
                {menuOpen ? '✖' : '☰'}
            </button>
            <nav className={menuOpen ? 'open' : ''}>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/about">About</a></li>
                    <li><a href="/contact">Contact</a></li>
                    {token && username ? (
                        <li className="dropdown">
                            <a href="/profile">
                                <FaUser className="userprofile"/>
                                {username}
                            </a>
                            <div className="dropdown-content">
                                <a href="/profile">Profile</a>
                                <a onClick={handleLogout}>Logout</a>
                            </div>
                        </li>
                    ) : (
                        <>
                            <li><a href="/login">Login</a></li>
                            <li><a href="/register">Register</a></li>
                        </>
                    )}
                </ul>
            </nav>
        </header>
        </div>
    );
};

export default Header;