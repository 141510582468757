import React, { useEffect, useState } from "react";
import "./PublishedArticles.css";
import api from "./Api";

const PublishedArticles = () => {
  const [articles, setArticles] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    loadArticles();
  }, []);

  const loadArticles = async () => {
    try {
      const response = await api.get("/blogs/myarticles");
  
      // Convert each article's `coverImage` to Base64 asynchronously
      const processedArticles = await Promise.all(
        response.map(async (article) => ({
          ...article,
          coverImage: await imageToBase64(article.coverImage),
        }))
      );
  
      setArticles(processedArticles);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };
  
  const imageToBase64 = async (profile) => `data:image/JPG;base64,${profile}`;

  const handleView = (article) => {
    setSelectedArticle(article);
    alert(article.coverImage)
    setIsModalOpen(true);
  };

  const handleDelete=async (id) => {
    if (window.confirm("Are you sure you want to Delete? You will be not able to resotre once delete")) {
    try {
        const response = await api.delete("/blogs/delete?id="+id);
        console.log("Article Deleted successfully", response);
        alert("Article Deleted Successfully");
    } catch (error) {
        console.error("Error Deleting article", error);
        alert("Failed to Deleting article");
      }
    }
  }
  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    return btoa(binary);
  };
  
  

  return (
    <div className="published-articles-container">
      <h2>Published Articles</h2>
      <div className="articles-row">
        {articles.length > 0 ? (
          articles.map((article) => (
            <div key={article.id} className=" col-md-7 mb-3">
              <div className="p-3 shadow">
                <h5>{article.title}</h5>
                <p className="text-truncate" dangerouslySetInnerHTML={{ __html: article.content.substring(0, 77) }}></p>
                <div className="d-flex justify-content-between">
                  <p><i className="fas fa-calendar-alt"></i> {new Date(article.createdAt).toLocaleDateString()}</p>
                  <div className="d-flex">
                    <p className="mr-2">❤️ {article.likes }&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </p>
                    <p>👁️ {article.views}</p>
                  </div>
                </div>
                <div className="article-actions d-flex justify-content-between">
                  <button className="btn btn-primary" onClick={() => handleView(article)}>
                    View
                  </button>
                  <button className="btn btn-danger" onClick={() => handleDelete(article.id)}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="no-articles col-12">
            <p>No published articles found.</p>
          </div>
        )}
      </div>

      {/* Modal for Viewing Article */}
      {isModalOpen && selectedArticle && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-btn" onClick={() => setIsModalOpen(false)}>✖</button>
            <h2>{selectedArticle.title}</h2>
            <img
              src={selectedArticle.coverImage}
              alt="Cover"
              className="article-cover"
              width="200px"
            />
            <div dangerouslySetInnerHTML={{ __html: selectedArticle.content }} className="article-content" />
          </div>
        </div>
      )}
    </div>
  );
};

export default PublishedArticles;
