import axios from 'axios';
import Cookies from 'js-cookie';
const api = axios.create({
  // baseURL: 'https://blogs.quantum-stack.com/api', // Replace with your backend URL
  baseURL: 'https://blogs.quantum-stack.com/api',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});
// Request interceptor to add JWT token to headers
api.interceptors.request.use(
  (config) => {
    const token = Cookies.get('jwtToken');
    if (token && !config.url.includes('generateToken')) {
      config.headers['Authorization'] = `Bearer ${token}`; // Add the token to the Authorization header
    }
    return config;
  },
  (error) => Promise.reject(error)
);
// Response interceptor to handle the new API response structure
api.interceptors.response.use(
  (response) => {
    const { data } = response;
    // Check for the expected structure
      return data; // Return only the data part for easier handling in the app
  },
  (error) => {
    try{
    console.error('API call failed:', error);
    if (error.response?.data?.includes('JWT token is expired')) {
      alert('Session expired, redirecting to login page...');
      window.location.href = '/';
    } else {
      // alert(error.response?.data || 'An unexpected error occurred');
    }
    return Promise.reject(error);
  }catch(err){
    
  }
  }
);
export default api;