import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Register.css';
import api from './Api';

const Register = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        name: '',
        gender: '',
        email: '',
        phone: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/auth/register', formData);
            if(response){
                alert(response);
                navigate('/login')
            }
            console.log('Registration successful:', response.data);
        } catch (error) {
            alert("Registration failed, try again!");
            console.error('Error registering:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="register-form">
           
            <div className="form-group">
                <label className="form-label">Name:</label>
                <input type="text" name="name" value={formData.name} onChange={handleChange} required className="form-input" />
            </div>
            <div className="form-group">
                <label className="form-label">Gender:</label>
                <select name="gender" value={formData.gender} onChange={handleChange} required className="form-select">
                    <option value="">Select Gender</option>
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                    <option value="OTHER">Other</option>
                </select>
            </div>
            <div className="form-group">
                <label className="form-label">Email:</label>
                <input type="email" name="email" value={formData.email} onChange={handleChange} required className="form-input" />
            </div>
            <div className="form-group">
                <label className="form-label">Phone:</label>
                <input type="text" name="phone" value={formData.phone} onChange={handleChange} required className="form-input" />
            </div>
            <div className="form-group">
                <label className="form-label">Username:</label>
                <input type="text" name="username" value={formData.username} onChange={handleChange} required className="form-input" />
            </div>
            <div className="form-group">
                <label className="form-label">Password:</label>
                <input type="password" name="password" value={formData.password} onChange={handleChange} required className="form-input" />
            </div>
            <button type="submit" className="form-button">Register</button>
        </form>
    );
};

export default Register;