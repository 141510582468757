import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import './Login.css';
import api from './Api';
import Cookies from 'js-cookie';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!username || !password) {
      setError('Please fill in all fields');
      return;
    }
    const data = await api.post('/auth/generateToken', { username, password });
   if(data){
      console.log(data); // handle successful login
      Cookies.set('jwtToken',data?.token);
      Cookies.set('userRole',data?.role);
      Cookies.set('username',data?.name);
      if(data?.role=='ROLE_ADMIN' || data?.role=="ROLE_WRITER"){
        navigate('/writerdash')
      }
      if(data?.role=='ROLE_USER'){
      navigate('/');
      }
   } else {
      setError('Invalid username or password');
    }
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={4}>
          <div className="login-form">
            <h2 className="text-center">Login</h2>
            {error && <p className="error-text">{error}</p>}
            <Form onSubmit={handleLogin}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Username </Form.Label>
                <Form.Control
                  type="username"
                  placeholder="Enter username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password &nbsp;</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>

              <Button variant="primary" type="submit" className="w-100">
                Login
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
