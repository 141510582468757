import React from 'react';
import './About.css';
import Header from './Header';

const About = () => {
    return (
        <>    <Header />
        <div className="about-container">
         
            <h1 className="about-title">About This Blog</h1>
            <p className="about-description">Welcome to our blog! Here, we share insightful articles on various topics ranging from technology to lifestyle. Our goal is to provide valuable content that can help you stay informed and inspired.</p>
            <p className="about-thankyou">We hope you enjoy reading our posts as much as we enjoy writing them. Thank you for visiting!</p>
        </div>
        </>
    );
};

export default About;