import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import WriterDashboard from './WriterDashboard';
import HomePage from './HomePage';
import ArticlePage from "./ArticlePage";
import About from './About';
import Contact from './Contact';
import Register from './Register';


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="login" element={<Login />} />
        <Route path="/blog/:slug" element={<ArticlePage />} />
        <Route path="register" element={<Register />} />
        <Route path="writerdash" element ={<WriterDashboard/> } />
  
      </Routes>
    </Router>
  );
};

export default App;
