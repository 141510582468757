import React, { useEffect, useRef, useState } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import './TextEditor.css';
import { setContaint, getContaint } from './ArticleContent';


const TextEditor = () => {
  const editorRef = useRef(null);
  const quillRef = useRef(null);
  const [content, setContent] = useState("");

  useEffect(() => {
    if (!editorRef.current || quillRef.current) return;
    if(getContaint()) setContent(getContaint())

    // Initialize Quill
    const quill = new Quill(editorRef.current, {
      theme: "snow",
      placeholder: "Start writing your article...",
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ font: [] }],
          [{ size: ["small", false, "large", "huge"] }],
          ["bold", "italic", "underline", "strike"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ align: [] }],
          ["code-block", "blockquote"],
          ["link", "image"],
          [{ color: [] }, { background: [] }],
          ["clean"],
        ],
      },
    });

    quillRef.current = quill;

    // Ensure event listener works
    const handleTextChange = () => {
      setContaint(quill.root.innerHTML)
      console.log("Quill Content Changed:", quill.root.innerHTML);
      setContent(quill.root.innerHTML);

    };

    quill.off("text-change", handleTextChange);

    return () => {
      quill.on("text-change", handleTextChange);
    };
  }, []);

  return (
    <div>
      <div ref={editorRef} style={{ height: "400px" }} />
      <p>Content Preview:</p>
      <div dangerouslySetInnerHTML={{ __html: content }} style={{ border: "1px solid #ccc", padding: "10px" }} />
    </div>
  );
};

export default TextEditor;
