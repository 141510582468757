import React, { useEffect, useState } from "react";
import api from "./Api";
import "./HomePage.css";
import "./ArticlePage.css";
import { Link } from "react-router-dom";
import Header from "./Header";

const HomePage = () => {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [trendingArticles, setTrendingArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  


  useEffect(() => {
    fetchArticles();
    fetchCategories();
  }, []);

  const fetchArticles = async () => {
    try {
      const response = await api.get("/blogs/all");
      const processedArticles = response.map((article) => ({
        ...article,
        coverImage: article.coverImage
          ? `data:image/jpeg;base64,${article.coverImage}`
          : null,
      }));

      // const trending = processedArticles.slice(0, 5);
      // setTrendingArticles(trending);

     const nonTrendingArticles = processedArticles;

      // const nonTrendingArticles = processedArticles.filter(
      //   (article) => !trending.some((trend) => trend.id == article.id)
      // );

      setArticles(nonTrendingArticles);
      setFilteredArticles(nonTrendingArticles);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const categ = ["Lifestyle", "Business", "Health", "Tech"]
      setCategories(["Select Category ", ...categ]);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchComments = async (articleId) => {
    try {
      const response = await api.get(`/blogs/${articleId}/comments`);
      setComments(response);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const handleLike = async () => {
    try {
      setSelectedArticle({ ...selectedArticle, likes: selectedArticle.likes + 1 });
      await api.post(`/blogs/like/${selectedArticle.slug}`);
      setSelectedArticle({ ...selectedArticle, likes: selectedArticle.likes + 1 });
    } catch (error) {
      console.error("Error liking article:", error);
    }
  };

  const handleCommentSubmit = async () => {
    if (!newComment.trim()) return;
    try {
      await api.post(`/blogs/${selectedArticle.id}/comments`, {
        comment: newComment,
      });
      setComments([...comments, { comment: newComment }]);
      setNewComment("");
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const handleNextArticle = () => {
    const currentIndex = articles.findIndex((a) => a.id === selectedArticle.id);
    if (currentIndex < articles.length - 1) {
      setSelectedArticle(articles[currentIndex + 1]);
      fetchComments(articles[currentIndex + 1].id);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    filterArticles(query, selectedCategory);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    filterArticles(searchQuery, category);
  };

  const filterArticles = (query, category) => {
    let filtered = articles;
    if (category !== "All") {
      filtered = filtered.filter((article) => article.category === category);
    }
    if (query) {
      filtered = filtered.filter((article) =>
        article.title.toLowerCase().includes(query)
      );
    }
    setFilteredArticles(filtered);
  };

  const truncateContent = (content, length = 100) => {
    if (!content) return "";
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;
    const plainText = tempDiv.innerText;
    return plainText.length > length ? plainText.substring(0, length) + "..." : plainText;
  };

  return (
    <>
      <Header />
      {selectedArticle ? (
        <div className="article-page">
          <button className="back-btn" onClick={() => setSelectedArticle(null)}>
            ⬅ Back
          </button>

          {selectedArticle.coverImage && (
            <img
              src={selectedArticle.coverImage}
              alt={selectedArticle.title}
              className="article-image"
            />
          )}

          <h1>{selectedArticle.title}</h1>

          <div className="article-meta">
            <span><strong>Category:</strong> {selectedArticle.category}</span>
            <span><strong>Author:</strong> {selectedArticle.author}</span>
            <span><strong>Published:</strong> {selectedArticle.publishDate}</span>
            <span><strong>Likes:</strong> {selectedArticle.likes}</span>
            {selectedArticle.tags.length > 0 && (
              <span>
                <strong>Tags:</strong> {selectedArticle.tags.join(", ")}
              </span>
            )}
          </div>

          <p
            className="article-content"
            dangerouslySetInnerHTML={{ __html: selectedArticle.content }}
          ></p>

          <div className="article-actions">
            <button className="like-btn" onClick={handleLike}> {selectedArticle.likes} ❤️  Like</button>
            <button className="view-btn" > {selectedArticle.views} 👁️  Views</button>
            <button className="next-btn" onClick={handleNextArticle}>➡ Next Article</button>
          </div>

          <div className="comments-section">
            <h3>Comments</h3>
            <ul>
              {comments.map((comment, index) => (
                <li key={index}>{comment.comment}</li>
              ))}
            </ul>
            <textarea
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Add a comment..."
            ></textarea>
            <button onClick={handleCommentSubmit}>Submit</button>
          </div>
          <div className="social-share">
            <h3>Share this article:</h3>
            <a href={`https://wa.me/?text=${window.location.href}`} target="_blank" rel="noopener noreferrer">📱 WhatsApp</a>
            <a href={`https://www.linkedin.com/shareArticle?url=${window.location.href}`} target="_blank" rel="noopener noreferrer">🔗 LinkedIn</a>
            <a href={`https://twitter.com/intent/tweet?url=${window.location.href}`} target="_blank" rel="noopener noreferrer">🐦 Twitter</a>
          </div>
        </div>
      ) : (
        <div className="homepage-container">
          <div className="search-categories">
          <header className="homepage-header">
            <input
              type="text"
              placeholder="Search articles..."
              value={searchQuery}
              onChange={handleSearch}
            />
          </header>
          <div className="categories-container">
            <select
              value={selectedCategory}
              onChange={(e) => handleCategoryChange(e.target.value)}
              className="styled-dropdown"
            >
              {categories.map((cat) => (
                <option key={cat} value={cat}>
                  {cat}
                </option>
              ))}
            </select>
          </div>
          </div>

          <section className="trending-section">
            <h2>Trending Articles</h2>
            <div className="article-list">
              {trendingArticles.map((article) => (
                <div key={article.id} className="article-card">
                  {article.coverImage && (
                    <img src={article.coverImage} alt={article.title} />
                  )}
                  <h3>{article.title}</h3>
                  <p dangerouslySetInnerHTML={{ __html: truncateContent(article.content, 120) }}></p>
                  <Link to={`/blogs/${article.slug}`} className="read-more">
                    Read More →
                  </Link>
                </div>
              ))}
            </div>
          </section>

          <section className="articles-section">
            <h2>All Articles</h2>
            <div className="article-list">
              {filteredArticles.map((article) => (
                <div key={article.id} className="article-card">
                  {article.coverImage && (
                    <img src={article.coverImage} alt={article.title} />
                  )}
                  <h3>{article.title}</h3>
                  <p dangerouslySetInnerHTML={{ __html: truncateContent(article.content, 120) }}></p>
                  <Link to={`/blog/${article.slug}`} className="read-more">
                    Read More →
                  </Link>
                </div>
              ))}
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default HomePage;
